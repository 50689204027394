@charset "utf-8";
// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

$family-sans-serif: "Nunito", sans-serif;
$navbar-item-img-max-height: 2.8rem;

$blue: #00589f;
$orange:#d6560f;
$purple: #0b055b;
$light-blue: #52a9ef;

$info: $blue;
$danger: $orange;
$content-heading-color: $blue;
$link: $light-blue;
$link-hover: $blue;
// Import only what you need from Bulma
@import "../../node_modules/bulma/bulma.sass";
// @import "../node_modules/bulma/sass/utilities/_all.sass";
// @import "../node_modules/bulma/sass/base/_all.sass";
// @import "../node_modules/bulma/sass/elements/button.sass";
// @import "../node_modules/bulma/sass/elements/container.sass";
// @import "../node_modules/bulma/sass/elements/form.sass";
// @import "../node_modules/bulma/sass/elements/title.sass";
// @import "../node_modules/bulma/sass/components/navbar.sass";
// @import "../node_modules/bulma/sass/layout/hero.sass";
// @import "../node_modules/bulma/sass/layout/section.sass";
// @import "../node_modules/bulma/sass/grid/columns.sass";