.header-button-nav{
    border: none;
  cursor: pointer;
  background-color: transparent;
}

.header-button-nav:hover,
.header-button-nav:focus {
text-decoration: none;
}

.tile-home {
  padding: 1.25rem 2.5rem;
}

.card-element {
  padding: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
}